import React, { useEffect } from 'react';
import "./TermsCondition.css";
import { storImagePath } from '../../../Utils/globalFunctions/GlobalFunction';
import logo from '../../assets/Logo1.png'
import Footer from '../../Components/home/Footer/Footer';

function TermsAndConditions() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="tncbannerpart">
        <img src={`${storImagePath()}/images/HomePage/Terms-and-Condtions/TermConditionMainBanner.jpg`} alt="#mainbanner" className="mainbanner_tnc" />
        {/* <div><img src={logo} className="tnclogo" alt="#logo" /></div> */}
      </div>
      <div>
        <div className="tnctitle centerall_tnc">TERMS AND CONDITIONS</div>
        <div className="tnc_content">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sem nulla pharetra diam sit amet nisl suscipit adipiscing bibendum. Leo in vitae turpis massa sed. Magna etiam tempor orci eu lobortis elementum nibh. Ultricies leo integer malesuada nunc vel risus commodo viverra maecenas.
        </div>
        <div className="contentboxtnc">
          <div className="tnctitle_2">Product Availability</div>
          <div className="tnc_content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae tempus quam pellentesque nec nam aliquam sem et tortor. Senectus et netus et malesuada. Vitae suscipit tellus mauris a. Donec massa sapien faucibus et molestie.
          </div>
          <br /><br />
          <div className="tnctitle_2">Information on our Site</div>
          <div className="tnc_content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tristique magna sit amet purus. Dui sapien eget mi proin. Fringilla urna porttitor rhoncus dolor purus non enim praesent elementum. Viverra orci sagittis eu volutpat odio.            
          </div>
          <br /><br />
          <div className="tnctitle_2">Return Policy</div>
          <div className="tnc_content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Suspendisse potenti nullam ac tortor vitae purus faucibus. Facilisis magna etiam tempor orci. Ornare aenean euismod elementum nisi quis eleifend quam adipiscing vitae. Enim nec dui nunc mattis.
          </div>
          <br /><br />
          <div className="tnctitle_2">Shipping Policy</div>
          <div className="tnc_content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Viverra justo nec ultrices dui sapien eget mi proin sed. Pellentesque id nibh tortor id aliquet lectus. Eget sit amet tellus cras adipiscing enim eu turpis egestas. Venenatis a condimentum vitae sapien pellentesque habitant morbi tristique senectus.
          </div>
          <br /><br />
          <div className="tnctitle_2">Limited Warranty</div>
          <div className="tnc_content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed adipiscing diam donec adipiscing tristique risus nec feugiat. Ultrices eros in cursus turpis massa tincidunt dui. Urna nec tincidunt praesent semper feugiat nibh sed. Sem et tortor consequat id porta nibh venenatis cras.
          </div>
          <br /><br />
          <div className="tnc_content">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Et malesuada fames ac turpis egestas maecenas pharetra convallis. Massa eget egestas purus viverra accumsan in nisl nisi. Faucibus pulvinar elementum integer enim. Pretium viverra suspendisse potenti nullam ac tortor vitae purus.
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default TermsAndConditions;
