import React from 'react'
import demo1img from '../../../../assets/demoImg1.jpg'
import './Styles.css'
import { storImagePath } from '../../../../../Utils/globalFunctions/GlobalFunction'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {Autoplay, Pagination, Navigation } from 'swiper/modules';

var BrandsContent = [
    {
        "image": "/images/HomePage/Brands/BrandImg01.jpg",
        "title": "Beyond Basic ",
        "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur doloribus voluptatum expedita magnam similique perferendis consectetur eos animi excepturi dolores. Hic amet tempora, deserunt veritatis sint eligendi eius esse dolores."
    },
    {
        "image":  "/images/HomePage/Brands/BrandImg06.jpg",
        "title": "Storyst",
        "description": "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ducimus eius iure voluptatum facilis cum quisquam aperiam aut pariatur rerum sunt, optio consectetur doloremque provident, tempora architecto ut beatae, cupiditate officiis!"
    },
    {
        "image":  "/images/HomePage/Brands/BrandImg04.jpg",
        "title": "Nuera",
        "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus dolor quo omnis esse eius! Nostrum, labore fugit quasi provident voluptas ratione est debitis eaque, quia doloribus aliquid! Veritatis, libero deleniti."
    },
    {
        "image":  "/images/HomePage/Brands/BrandImg05.jpg",
        "title": "Promise",
        "description": "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Maiores impedit aperiam accusantium fugiat consequatur dolores libero assumenda veritatis quibusdam odio tempora eius porro sint beatae consectetur dicta soluta, totam nostrum, qui id iusto. Quod explicabo blanditiis illo qui ipsum labore et, repudiandae repellat adipisci ullam exercitationem, necessitatibus odio possimus ex iure voluptatibus cum. Quod eum consectetur consequuntur? Assumenda, blanditiis incidunt vero et deleniti, expedita possimus commodi exercitationem dolor quam doloremque id! Molestiae odit suscipit maiores dolor architecto, ipsam sed, corporis minus voluptatibus voluptas, harum unde ex libero facilis laudantium! Illo ad, officiis minima laboriosam delectus laborum velit similique rem eum."
    },
    {
        "image":  "/images/HomePage/Brands/BrandImg02.jpg",
        "title": "Diament",
        "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum maxime ab voluptatum libero, et nulla eaque itaque molestias eum neque delectus! Autem facere perspiciatis omnis sequi amet necessitatibus molestiae possimus vitae, odio neque id, reiciendis, illo impedit. Incidunt provident architecto quia aut quisquam porro ad ullam neque officiis hic non quaerat dolorem, magnam blanditiis? Ex fugit eaque, delectus labore veritatis necessitatibus natus ipsa porro cumque esse quia debitis velit rerum?"
    },
    {
        "image":"/images/HomePage/Brands/BrandImg03.jpg",
        "title": "Lovent",
        "description": "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptatem labore aliquid molestiae in reiciendis temporibus non officiis! Esse autem, ullam similique nemo natus molestias repellat, fugiat nisi accusantium eveniet soluta."
    }
];


const PromoComponent1 = () => {
    return (
        <div className='mt-5'>
            <Swiper
                pagination={{ clickable: true }}
                className="mySwiper"
                loop={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay]}
            >
                {BrandsContent.map((item, index) => (
                    <SwiperSlide key={index}>
                        <div className='promo-daimondBoxMain'>
                            <div className='promo-daimondBox1'>
                            <p style={{ fontSize: '22px', color: 'rgba(29, 50, 88, 0.8)', fontWeight:'600' }}>{item?.title}</p>
                                <p style={{ fontSize: '22px', color: 'rgba(29, 50, 88, 0.8)' }}>{item.description}</p>
                            </div>
                            <div className='promo-daimondBox2'>
                                <img src={storImagePath() + item.image} className='promo-daimondBox2-image' alt={`Item ${index + 1}`} />
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}

export default PromoComponent1