import React, { useEffect } from 'react';
import "./CustomerService.css"; // Assuming you have a CSS file for styling
import { storImagePath } from '../../../Utils/globalFunctions/GlobalFunction';
import Footer from '../../Components/home/Footer/Footer';

function CustomerService() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div>
        {/* Main Banner */}
        <img src={`${storImagePath()}/images/HomePage/CustomerService/CustomerServiceMainBanner1Img.jpg`} className="bannercs" alt="#banner" />
      </div>
      <div>
        {/* Title */}
        <div className="cstitle centerall_cs">SALES SERVICE</div>
        {/* Content */}
        <div className="cs_content">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eros donec ac odio tempor orci dapibus ultrices in. Amet volutpat consequat mauris nunc congue. Facilisis mauris sit amet massa. Tellus mauris a diam maecenas sed enim.
        </div>
      </div>
      <div className="cs_section">
        {/* Engraving Service */}
        <div className="cs_section_box">
          <div><img src={`${storImagePath()}/images/HomePage/CustomerService/CustomerServicesSubnBanner1Img.png`} alt="#engrave" className="cs_section_img" /></div>
          <div className="cs_section_box_title">Engraving Service</div>
          <div className="cs_section_box_title_2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Libero nunc consequat interdum varius sit amet mattis. Neque egestas congue quisque egestas diam in arcu cursus euismod. Urna duis convallis convallis tellus id interdum. Purus faucibus ornare suspendisse sed nisi lacus sed viverra tellus.
          </div>
        </div>
        {/* Cleaning and Polishing */}
        <div className="cs_section_box">
          <div><img src={`${storImagePath()}/images/HomePage/CustomerService/CustomerServicesSubnBanner2Img.jpg`} alt="#engrave" className="cs_section_img" /></div>
          <div className="cs_section_box_title">Cleaning and Polishing</div>
          <div className="cs_section_box_title_2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Pellentesque massa placerat duis ultricies lacus. Congue nisi vitae suscipit tellus mauris a diam. Adipiscing elit ut aliquam purus. Sed risus pretium quam vulputate.                  
          </div>
        </div>
        {/* Repair Service */}
        <div className="cs_section_box">
          <div><img src={`${storImagePath()}/images/HomePage/CustomerService/CustomerServicesSubnBanner3Img.jpg`} alt="#engrave" className="cs_section_img" /></div>
          <div className="cs_section_box_title">Repair Service</div>
          <div className="cs_section_box_title_2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sit amet facilisis magna etiam tempor orci. Est pellentesque elit ullamcorper dignissim cras tincidunt. In fermentum posuere urna nec tincidunt praesent semper feugiat nibh. Pharetra sit amet aliquam id diam maecenas ultricies mi eget.
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default CustomerService;
