import React, { useEffect } from 'react';
import "./customize.css";
import { storImagePath } from '../../../Utils/globalFunctions/GlobalFunction';
import Footer from '../../Components/home/Footer/Footer';

function Customize() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div>
        <img src={`${storImagePath()}/images/HomePage/Customize/CustomizeMainBanner.png`} className="bannercust" alt="#bannercust" />
      </div>
      <div>
        <div className="custtitle centerall_cust">Build your own unique design</div>
        <div className="custtitle centerall_cust">ante in nibh mauris cursus</div>
        <div className="cust_content">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sagittis id consectetur purus ut faucibus. Amet consectetur adipiscing elit duis tristique sollicitudin nibh sit. Laoreet non curabitur gravida arcu ac tortor. Dictumst quisque sagittis purus sit amet volutpat.
        </div>
      </div>
      <div className="cust_Section">
        <div><img src={`${storImagePath()}/images/HomePage/Customize/CustomizeSubBanner1.jpg`} alt="#custimg" className="cust_Section_img" /></div>
        <div className="cust_Section_info">
          <div className="cust_Section_info_1">mauris a diam maecenas sed</div>
          <div className="cust_Section_info_2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elit duis tristique sollicitudin nibh sit amet. Habitant morbi tristique senectus et netus et malesuada fames. Massa tempor nec feugiat nisl pretium fusce. Donec ultrices tincidunt arcu non sodales neque sodales ut etiam.</div>
        </div>
      </div>
      <div className="cust_Section">
        <div><img src={`${storImagePath()}/images/HomePage/Customize/CustomizeSubBanner2.jpg`} alt="#custimg" className="cust_Section_img" /></div>
        <div className="cust_Section_info">
          <div className="cust_Section_info_1">elit at imperdiet dui accumsan</div>
          <div className="cust_Section_info_2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Auctor urna nunc id cursus metus aliquam eleifend. Pellentesque habitant morbi tristique senectus et netus et malesuada fames. Nulla pharetra diam sit amet nisl suscipit adipiscing bibendum. Id interdum velit laoreet id donec ultrices tincidunt arcu.</div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Customize;
