import React, { useEffect, useState } from 'react';
import "./AboutUs.css"
import Footer from '../../Components/home/Footer/Footer';
import { storImagePath } from '../../../Utils/globalFunctions/GlobalFunction';
import CompanyBanner from '../../Components/home/ComapnayData/CompanyData';

function AboutUs() {
  const [activeTab, setActiveTab] = useState(1);

  const checkDetail = (tabIndex) => {
    setActiveTab(tabIndex);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const renderTabContent = () => {
    switch (activeTab) {
      case 1:
        return (
          <div className="aboutustabinfo">
            Facilisis sed odio morbi quis commodo odio aenean sed. Mauris pharetra et ultrices neque ornare aenean euismod. Et odio pellentesque diam volutpat commodo sed egestas egestas. Bibendum at varius vel pharetra vel turpis nunc eget lorem. Lacus laoreet non curabitur gravida arcu ac tortor dignissim. Orci porta non pulvinar neque laoreet suspendisse interdum consectetur libero. Erat velit scelerisque in dictum non consectetur. Amet consectetur adipiscing elit ut aliquam purus sit amet luctus. Massa ultricies mi quis hendrerit.
            <br />
            <br />
            Massa tincidunt nunc pulvinar sapien. Cursus metus aliquam eleifend mi. In iaculis nunc sed augue lacus viverra vitae congue eu. Bibendum enim facilisis gravida neque convallis a cras. Sed vulputate mi sit amet mauris. Vel fringilla est ullamcorper eget nulla facilisi etiam. Id eu nisl nunc mi ipsum faucibus vitae aliquet nec.
            <br />
            <br />
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nisl pretium fusce id velit ut tortor pretium viverra suspendisse. Donec ultrices tincidunt arcu non sodales. Urna condimentum mattis pellentesque id nibh tortor id aliquet. Vulputate enim nulla aliquet porttitor lacus luctus accumsan. Neque vitae tempus quam pellentesque nec. Cursus mattis molestie a iaculis at erat pellentesque adipiscing commodo. Phasellus egestas tellus rutrum tellus pellentesque eu tincidunt tortor aliquam. Mauris pellentesque pulvinar pellentesque habitant morbi tristique. Porttitor leo a diam sollicitudin tempor id eu nisl. Nulla aliquet porttitor lacus luctus accumsan tortor posuere ac ut. In nisl nisi scelerisque eu ultrices vitae. Cras ornare arcu dui vivamus arcu felis. Neque vitae tempus quam pellentesque nec nam.
          </div>
        );
      case 2:
        return (
          <div className="aboutustabinfo">
            Scelerisque viverra mauris in aliquam sem fringilla ut. Lacus viverra vitae congue eu. Pretium vulputate sapien nec sagittis aliquam. Natoque penatibus et magnis dis parturient montes nascetur ridiculus mus. Velit dignissim sodales ut eu sem integer vitae. Habitant morbi tristique senectus et netus et malesuada. Gravida cum sociis natoque penatibus. Est ultricies integer quis auctor elit sed vulputate mi sit. Orci dapibus ultrices in iaculis nunc. Non nisi est sit amet facilisis magna. Integer eget aliquet nibh praesent tristique magna sit amet. Accumsan sit amet nulla facilisi morbi. Fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis. Nullam non nisi est sit amet facilisis magna etiam.
          </div>
        );
      case 3:
        return (
          <div className="aboutustabinfo">
            Venenatis cras sed felis eget. Nunc mattis enim ut tellus. Et odio pellentesque diam volutpat commodo sed. Nibh tellus molestie nunc non blandit massa enim nec. Id donec ultrices tincidunt arcu. Aliquet porttitor lacus luctus accumsan tortor posuere ac. Sed risus pretium quam vulputate dignissim. Elit sed vulputate mi sit amet mauris commodo quis. Semper auctor neque vitae tempus quam pellentesque nec. Urna duis convallis convallis tellus id interdum velit. Faucibus a pellentesque sit amet porttitor eget.
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div>
        <img
          src={`${storImagePath()}/images/HomePage/AboutUs/AboutUsMainBannerImg.jpg`}
          alt="#mainbanner"
          className="mainbannerabus"
        />
      </div>
      <div className="aboutustitle centerall">about us</div>
      <div className="aboutuscontent">
        <div className="tabpart">
          <div className="tabpart_tabs">
            <div
              className={`aboutustabtitle ${activeTab === 1 ? 'active' : ''}`}
              onClick={() => checkDetail(1)}
            >
              ABOUT
            </div>
            <div
              className={`aboutustabtitle ${activeTab === 2 ? 'active' : ''}`}
              onClick={() => checkDetail(2)}
            >
              OUR MISSION
            </div>
            <div
              className={`aboutustabtitle ${activeTab === 3 ? 'active' : ''}`}
              onClick={() => checkDetail(3)}
            >
              OUR GOAL
            </div>
          </div>
          {renderTabContent()}
        </div>
        <div className="imgpart">
          <img
            src={`${storImagePath()}/images/HomePage/AboutUs/AboutUsVisitngImg.jpg`}
            className="visitimgau"
            alt="#content"
          />
        </div>
      </div>
      <CompanyBanner />
      {/* <div className="aboutuscompany">
        <div className="aboutuscompany_w_1">
          <div className="aboutuscompany_w_1_info">Established Excellence</div>
          <div className='aboutdesc'>A Legacy of success, dream by passion and innovation</div>
        </div>
        <div className="aboutuscompany_w">
          <div className="aboutuscompany_w_1_info">6</div>
          <div>Working Year</div>
        </div>
        <div className="aboutuscompany_w">
          <div className="aboutuscompany_w_1_info">150+</div>
          <div>Happy Client</div>
        </div>
        <div className="aboutuscompany_w">
          <div className="aboutuscompany_w_1_info">5000+</div>
          <div>Design</div>
        </div>
        <div className="aboutuscompany_w">
          <div className="aboutuscompany_w_1_info">450+</div>
          <div>Worker & Team</div>
        </div>
      </div> */}
      <Footer />
    </div>
  );
}

export default AboutUs;
