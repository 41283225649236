import React, { useEffect } from 'react';
import './HistoryPage.css';
import Footer from '../../Components/home/Footer/Footer';
import { storImagePath } from '../../../Utils/globalFunctions/GlobalFunction';

function History() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="hsitroymainbanner">
        <img
          src={`${storImagePath()}/images/HomePage/History/HistoryMainBanner.jpg`}
          alt="#mainbanner"
          className="mainbanner_h"
        />
      </div>
      <div>
        <div className="historytitle centerall_h">OUR HISTORY</div>
        <div className="history_content">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Adipiscing enim eu turpis egestas pretium aenean pharetra. Sit amet purus gravida quis blandit turpis cursus. In fermentum posuere urna nec tincidunt praesent semper feugiat. Diam quis enim lobortis scelerisque fermentum dui faucibus in ornare. Enim sit amet venenatis urna cursus. A scelerisque purus semper eget duis at tellus. Vestibulum morbi blandit cursus risus at ultrices mi tempus. Aliquam faucibus purus in massa. Et malesuada fames ac turpis egestas integer.
        </div>
      </div>
      <div className="history_journey">
        <div className="history_journey_1">
          <div className="hbox1">
            <div className="hbox1_1" />
            <div className="hbox1_2">
              <div className="hbox1_2_info">2010</div>
              <div className="hbox1_2_info_content">created new</div>
            </div>
          </div>
          <br />
          <br />
          <div className="hbox1">
            <div className="hbox1_1">
              <div className="hbox1_2_info end_h">2011</div>
              <div className="hbox1_2_info_content end_h">
                in aliquam sem fringilla ut
              </div>
            </div>
            <div className="hbox1_2" />
          </div>
          <br />
          <br />
          <div className="hbox1">
            <div className="hbox1_1" />
            <div className="hbox1_2">
              <div className="hbox1_2_info">2012</div>
              <div className="hbox1_2_info_content">
                neque laoreet suspendisse interdum consectetur
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="hbox1">
            <div className="hbox1_1">
              <div className="hbox1_2_info end_h">2013</div>
              <div className="hbox1_2_info_content end_h">
                ultricies mi eget mauris pharetra
              </div>
            </div>
            <div className="hbox1_2" />
          </div>
          <br />
          <br />
          <div className="hbox1">
            <div className="hbox1_1" />
            <div className="hbox1_2">
              <div className="hbox1_2_info">2014</div>
              <div className="hbox1_2_info_content">ullamcorper dignissim cras tincidunt lobortis</div>
            </div>
          </div>
          <br />
          <br />
          <div className="hbox1">
            <div className="hbox1_1">
              <div className="hbox1_2_info end_h">2015</div>
              <div className="hbox1_2_info_content end_h">
                sit amet nisl purus in
              </div>
            </div>
            <div className="hbox1_2" />
          </div>
        </div>
        <div className="history_journey_2">
          <img
            src={`${storImagePath()}/images/HomePage/History/HistorySubBanner1.jpg`}
            className="h_ownerimg"
            alt="#historyimg"
          />
        </div>
      </div>
      <Footer />
    </div>

  );
}

export default History;
