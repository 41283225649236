import React from 'react'
import demo1img from '../../../../assets/demoImg1.jpg'
import './Styles.css'
import { storImagePath } from '../../../../../Utils/globalFunctions/GlobalFunction'
import { useNavigate } from 'react-router-dom'

const PromoComponent1 = () => {

    const navigation = useNavigate();

    return (
        <div className='mt-5'>
            <div className='promo-daimondBoxMain'>
                <div className='promo-daimondBox2'>
                    <img src={`${storImagePath()}/images/HomePage/Promo/Banner/PromoBanner1.jpg`} className='promo-daimondBox2-image' onClick={() => navigation('/productpage')} />
                </div>
                <div className='promo-daimondBox1'>
                    <p style={{ fontSize: '22px', color: 'rgba(29, 50, 88, 0.8)' }}>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Temporibus, rerum aut voluptate eius doloremque facilis labore autem, sequi ullam iusto, debitis inventore vitae. Modi rem, tempore exercitationem, quibusdam dolores minima consequatur nesciunt temporibus amet, aliquid vel architecto maiores quaerat quia consectetur perferendis sunt laudantium! Eaque commodi eos error deserunt! Aliquid rerum, doloremque quos odit voluptatem voluptates doloribus non natus. Hic dolorem quidem eligendi reiciendis iusto? Suscipit, sapiente? Repellat, sunt pariatur et nulla dolor ratione dignissimos qui modi? In quisquam, quasi laborum voluptates, earum voluptas totam neque perferendis harum recusandae amet veniam officiis ratione velit sapiente, vero explicabo libero aliquam eos?</p>
                </div>
            </div>
        </div>
    )
}

export default PromoComponent1